.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.tabsBlock {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dadada;
}
.loaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.activeTab,
.notActiveTab {
  flex: 1;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.notActiveTab {
  cursor: pointer;
  color: #727272;
}
.activeTab {
  color: var(--primary-color);
  position: relative;
}
.activeTabUnderLine {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}
.badgeIcon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.viewCertificateIcon path {
  stroke: white;
}
.certificateElement {
  border-radius: 20px;
  padding: 20px;
  width: calc(50% - 50px);
}
.certificateListBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.headCertificateBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.headCertificateBlock span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
}
.bottomCertificateBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.imagePreviewBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pdfWorker {
  height: 70svh;
  width: 50svw;
  margin-left: auto;
  margin-right: auto;
}
.previewImg {
  max-width: 50svw;
  max-height: 70svh;
}
.showCertificateBtn {
  cursor: pointer;
}
.educationDescription {
  font-weight: 600;
  display: block;
}
@media screen and (max-width: 768px) {
  .previewImg {
    max-width: 90svw;
  }
  .activeTab,
  .notActiveTab {
    font-size: 12px;
  }
  .certificateElement {
    width: 100%;
  }
  .certificateListBlock {
    max-height: 200px;
    overflow-y: auto;
  }
  .pdfWorker {
    width: 95vw;
  }
}
