.nameColumn,
.specColumn,
.emailColumn {
  color: white !important;
}
.emailColumn {
  display: flex !important;
  flex-direction: column !important;
}
.costColumn {
  color: var(--primary-color) !important;
}
.verifiedColumn {
  color: #3fb082;
  background-color: rgba(63, 176, 131, 0.25);
  padding: 5px 8px;
  border-radius: 10px;
}
.unverifiedColumn {
  color: #e34a4a;
  background-color: rgba(227, 74, 74, 0.25);
  padding: 5px 8px;
  border-radius: 10px;
}
.tableFooter {
  background-color: #313131;
  width: 100%;
}
.tableFooter svg path {
  fill: white;
}
.tableFooter option {
  color: black;
}
.tableHead th {
  font-weight: 600;
  border-bottom: 1px solid black;
}
.statusBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}
