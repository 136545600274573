.container {
  width: calc(100% - 40px);
  padding: 20px;
  background-color: #131313;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.headBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headBlock h2 {
  margin: 0;
}
.priceBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
}
.generalInfoBlock {
  display: flex;
  align-items: center;
  gap: 24px;
}
.generalInfoBlock span {
  display: flex;
  align-items: center;
  gap: 5px;
}
.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em; /* Adjust this based on your font size */
  max-height: 2.4em; /* line-height * number of lines (1.2em * 2) */
}
.detailsBtn {
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
}
.footerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -20px;
}
.authorNameBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}
.buyNowBtn {
  background-color: var(--secondary-color);
  color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 20px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: 600;
}
.btnsBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.deleteBtn {
  background-color: rgba(229, 90, 90, 0.2);
  color: var(--main-red);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 20px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .footerBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  .btnsBlock {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
  }
}
