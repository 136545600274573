.multiFilesBlock {
  display: flex;
  height: 200px;
  gap: 5px;
}
.columnFilesBlock {
  display: flex;
  flex-direction: column;
  width: 67%;
  gap: 5px;
}
.imgFile,
.videoFile {
  height: 205px;
  width: 100%;
  object-fit: cover;
}
.imgFileSmall,
.videoFileSmall {
  height: 100px;
  width: 100%;
  object-fit: cover;
}
.thirdFileBlock {
  height: 100px;
  position: relative;
  cursor: pointer;
}
.blurAllFilesBlock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  backdrop-filter: blur(2px);
}
.previewModalImageBlock {
  height: 80vh;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 5px;
}
.previewModalImage {
  max-width: 80vw;
  max-height: 80vh;
  object-fit: cover;
}
.previewModalImageHidden {
  display: none;
}
.thumbleBlock {
  display: flex;
  width: 80vw;
  justify-content: center;
}
.thumbleImages {
  width: calc(100% / 5);
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}
.notSelecthumb {
  filter: brightness(30%);
}
.selectSlideBlock {
  width: 80vw;
  height: calc(80vh);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chevronRight,
.notSelesctedRightCvhevron {
  transform: rotate(180deg);
}
.chevronLeft {
  margin-top: 50px;
}
.chevronRight,
.chevronLeft {
  cursor: pointer;
  width: 70px;
  height: 70px;
}
.notActiveChevronLeft,
.notSelesctedRightCvhevron {
  opacity: 0.5;
  width: 70px;
  height: 70px;
}
.rightActionSwiperBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
}
.closeModalIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
