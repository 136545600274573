.body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(#000000, #4d4d4d);
}

.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.loginBox h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.loginBox .userBox {
  position: relative;
}

.loginBox .userBox input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.loginBox .userBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.loginBox .userBox input:focus ~ label,
.loginBox .userBox input:valid ~ label {
  top: -20px;
  left: 0;
  color: #3fb082;
  font-size: 12px;
}

.loginNav {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #3fb082;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  letter-spacing: 4px;
  cursor: pointer;
}
.loginBtnBlock {
  display: flex;
  justify-content: center;
  width: 100%;
}
.loginNav:hover {
  background-image: linear-gradient(90deg, #3fb082, #b4d431);
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px #3fb082, 0 0 25px #3fb082, 0 0 50px #3fb082,
    0 0 100px #3fb082;
}

.loginNav span {
  position: absolute;
  display: block;
}
.errorMessage {
  color: red;
  margin-bottom: 0;
}
.loginNav span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #3fb082);
  animation: btn-anim1 2s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.loginNav span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #3fb082);
  animation: btn-anim2 2s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.loginNav span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #3fb082);
  animation: btn-anim3 2s linear infinite;
  animation-delay: 1s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.loginNav span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #3fb082);
  animation: btn-anim4 2s linear infinite;
  animation-delay: 1.5s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
