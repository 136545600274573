.modalOverlay {
  position: "fixed";
  display: "flex";
  justify-content: "center";
  top: "0";
  left: "0";
  width: "100%";
  height: "100%";
  background-color: "rgba(0,0,0, .8)";
  z-index: "1000";
  overflow-y: "auto";
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 24;
  padding: 20px 45px;
}
.modalContentRight {
  position: absolute;
  bottom: 0;
  right: 3%;
  width: 50%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 24;
  padding: 20px 45px;
}
.modal:focus {
  outline: none !important;
}
