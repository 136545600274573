.datePicker {
  background-color: rgb(46, 46, 46);
  border-radius: 20px;
  height: 43px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  color: white;
}
.datePicker div:nth-child(1) {
  width: 100%;
}
.datePicker input {
  padding: 10px;
  width: 100%;
  color: white;
}
