.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow: auto;
}
.activeTab,
.notActiveTab {
  position: relative;
  font-weight: 600;
}
.tabIndicator {
  position: absolute;
  bottom: -10px;
  height: 3px;
  background-color: var(--primary-color);
  width: 100%;
  left: 0;
  border-radius: 20px;
}
.tabsBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.activeTab {
  color: var(--primary-color);
}
.notActiveTab {
  color: white;
  cursor: pointer;
}
.tableBlock {
  width: 100%;
  position: static;
  z-index: 10;
}
.infoBlock {
  transition: margin 0.3s ease-in-out;
  height: 0;
  width: 60%;
  background-color: white;
  margin-right: 1rem;
  overflow: hidden;
  margin-left: -65.7%;
  border-radius: 20px;
  background-color: #282828;
  padding: 20px;
}
.contentBlock {
  display: flex;
  width: 100%;
}
.infoBlockActive {
  height: calc(100vh - 250px);
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}
.headerBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filtersBlock {
  color: white;
}
.filtersTitle {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
