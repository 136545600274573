.conteiner {
  display: flex;
  padding-right: 10px;
  width: 80vw;
}
.containerWithoutMedia {
  display: flex;
  padding-right: 10px;
  width: 600px;
}
.postBlock {
  margin-bottom: 20px;
  padding: 0 20px 0 20px;
}
.postInput {
  width: calc(100% - 6px);
  resize: none;
  border: none;
  outline: none;
  margin: 10px 0 0 0;
}
.postCommentsBlock {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(80vh - 40px);
  width: 100%;
}
.commentsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.textAreaBlock {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px 15px 20px;
}
.sendBtn {
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}
.sendBtn path {
  fill: var(--primary-color);
}
.commentContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.myCommentConteiner {
  background-color: #d9efe64d;
  color: black;
}
.otherCommentConteiner {
  background-color: transparent;
  color: black;
}
.commentsListBlock {
  overflow: auto;
  /*  padding-right: 10px; */
  min-height: 300px;
}
.commentContentBlock {
  border-radius: 20px 20px 20px 0;
  padding: 10px 0;
  width: 100%;
}

.nameCommentAuthorBlock {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.commentContentBlock span {
  font-size: 14px;
}

.commentActionBlock {
  display: flex;
  align-items: center;
  position: relative;
}
.editButtonBlock,
.deleteButtonBlock {
  cursor: pointer;
  transition: 0.3s;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: calc(100% - 30px);
}
.editIcon,
.deletIcon {
  margin-right: 10px;
}
.editIcon path,
.deletIcon path {
  transition: 0.3s;
}
.deleteButtonBlock:hover,
.editButtonBlock:hover {
  background-color: #b6e0d0;
  color: #075736;
}

.deleteButtonBlock:hover .deletIcon path,
.editButtonBlock:hover .editIcon path {
  stroke: #075736;
}
.deleteButtonBlock {
  border-radius: 0 0 20px 20px;
}
.editButtonBlock {
  border-radius: 20px 20px 0 0;
}
.editingBlock {
  display: flex;
  flex-direction: column;
}
.editingBlock span:nth-child(1) {
  color: var(--primary-color);
  margin-bottom: 5px;
}
.actionIconBtn {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
}
.actionIconBtn:hover .actionIconLike path {
  stroke: var(--primary-color);
}
.myselfLikeIcon path {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}
.likeBlock {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.myBaseLikeIcon path {
  stroke: white;
}
.footerComment {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  user-select: none;
}
.postImg,
.postImgHidden {
  height: calc(80vh - 20px);
  max-width: 50vw;
  object-fit: cover;
  border-radius: 20px;
  z-index: 1;
  position: relative;
}
.postImgHidden {
  display: none;
}
.mediaBlock {
  position: relative;
  width: 50vw;
  padding: 0 0 0 20px;
}
.postImgBlock {
  width: 50vw;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}
.bgImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  backdrop-filter: blur(10px);
  filter: blur(10px);
  /*   filter: brightness(60%); */
  border-radius: 20px;
}
.postVideoBlock {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(80vh - 40px);
}
.postVideoBlock video {
  width: 50vw;
  height: 100%;
}
.chevronRight {
  transform: rotate(180deg);
  top: calc(40vh - 20px);
  right: 10px;
}
.chevronRight,
.chevronLeft {
  width: 50px;
  height: 50px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  z-index: 2;
}
.chevronLeft {
  left: 10px;
  top: calc(40vh - 20px);
}
.actionCommentMenuBtn {
  margin-left: 20px;
  cursor: pointer;
}
.commentDate {
  color: #b5b8c6;
  font-weight: 400;
}
.actionMenuComment {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 10;
  left: -80px;
  top: 30px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.5);
  width: max-content;
}
@media screen and (max-width: 768px) {
  .conteiner,
  .containerWithoutMedia {
    flex-direction: column;
    width: 95vw;
    overflow: auto;
    padding: 0;
    height: 90vh;
  }
  .mediaBlock {
    padding: 10px;
  }
  .mediaBlock,
  .postImgBlock {
    width: calc(95vw - 20px);
    max-height: 50vh;
  }
  .postImg {
    max-width: calc(95vw - 20px);
  }
  .postImg {
    height: auto;
  }
  .bgImg {
    display: none;
  }
  .postCommentsBlock {
    overflow: auto;
  }
  .commentsListBlock {
    min-height: auto;
  }
  .chevronLeft,
  .chevronRight {
    top: 45%;
  }
  .chevronLeft {
    left: -5px;
  }
  .chevronRight {
    right: -5px;
  }
}
