.container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}
.createContentBlock {
  color: white;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 400px;
}
.createContentBlock h3 {
  text-align: center;
  margin: 0;
}
.addCategoryBtn {
  background-color: rgb(46, 46, 46);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.addCategoryBtn:hover path {
  stroke: var(--primary-color);
}
.categElemntBlock {
  padding: 20px;
  border-radius: 20px;
  color: white;
  background-color: rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.taxBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.taxElement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.primaryValue {
  font-weight: 600;
  color: var(--primary-color);
}
.actionBlock {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: -10px;
  margin-top: -10px;
}
.actionBtn {
  cursor: pointer;
}
.actionBtn:hover .editIcon path {
  stroke: rgb(255, 208, 0);
}

.actionBtn:hover .deleteIcon path {
  stroke: red;
}
