.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}
.mainUserInfoBlock {
  width: 30%;
  display: flex;
  align-items: center;
}
.mainUserTitleInfo {
  display: flex;
}
.mainUserTitleInfo span:nth-child(1) {
  font-size: 16px;
  margin-right: 10px;
}
.mainUserInfoBlock,
.locationuserBlock,
.emailUserBlock,
.ageUserBlock {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .genderUserBlock {
    display: none;
  }
}
