.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  color: white;
  border-radius: 20px;
}
.confirmTitle {
  text-align: center;
  padding: 0 10px;
  margin-bottom: 30px;
}
.actionBlock {
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.cancelButton {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  background-color: #e74c3c;
  border: 1px solid #e74c3c;
  border-radius: 18px;
  height: 40px;
  width: 120px;
  cursor: pointer;
  transition: 0.3s;
}
.cancelButton:hover {
  background-color: #9d3529;
}
.container h2 {
  text-align: center;
}
.warningBlock {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.warningIcon {
  width: 60px;
  height: 60px;
}
