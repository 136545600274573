.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow: auto;
}
.headBlock {
  display: flex;
  align-items: center;
}
.pageInfoTitleBlock {
  display: flex;
  flex-direction: column;
}
.pageInfoTitleBlock h1 {
  color: white;
  font-size: 32px;
  margin: 0 0 8px 0;
}
.pageInfoTitleBlock span {
  color: #727272;
  font-size: 14px;
}
.inviteSpecialistButtton {
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.filterBlock {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}
.filterIcon path {
  fill: white;
}
.searchInputBlock {
  width: 25%;
  position: relative;
  height: fit-content;
  margin-right: 50px;
}
.magnifierIcon {
  position: absolute;
  right: -5%;
  top: 25%;
}
.searchInput {
  width: 100%;
  background-color: #404040;
  padding: 10px 20px;
  border-radius: 20px;
  outline: none;
  color: white;
  border: none;
}

.contentBlock {
  margin-top: 20px;
  width: 100%;
}

.tableBlock {
  width: 100%;
  position: static;
  z-index: 10;
}
.specialistInfoBlock {
  transition: margin 0.3s ease-in-out;
  height: 0;
  width: 60%;
  background-color: white;
  margin-right: 1rem;
  overflow: hidden;
  margin-left: -65.7%;
  border-radius: 20px;
  background-color: #282828;
  padding: 20px;
}
.contentBlock {
  display: flex;
  width: 100%;
}
.specialistInfoBlockActive {
  height: calc(100vh - 250px);
  margin-left: 1rem;
}
.chevronBackIcon {
  margin-right: 5px;
  margin-top: 5px;
}
.backBlock {
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.headNameBlock {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.headNameBlock span {
  color: white;
  font-size: 24px;
  margin-right: 5px;
}
.mainInfoBlock {
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  height: fit-content;
  color: white;
}
.headNameBlock {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.nameBlock {
  display: flex;
  flex-direction: column;
}
.nameTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.locationTitle {
  font-size: 18px;
  color: var(--primary-color);
}
.emailHeadTitle {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.infoTitleBlock {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.infoTitleBlock span:nth-child(1) {
  font-size: 24px;
  font-weight: 600;
  margin-right: 2rem;
  text-wrap: nowrap;
}

.personInfoBlock {
  width: 80%;
}
.personInfoLineBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.personInfoElement {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.infoLable {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
}
.infoValue {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
}

.langBlock {
  display: flex;
  flex-wrap: wrap;
}
.langBlock span {
  margin-right: 10px;
}
.nameEmailBlock {
  display: flex;
  flex-direction: column;
}
.nameEmailBlock span:nth-child(2) {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}
