.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow: auto;
}
.mainLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statsListBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
}
.statisticCardBlock {
  width: 19%;
  height: 100%;
}
