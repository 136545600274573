.postsContainer {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.postEnter {
  opacity: 0;
  transform: translateX(-100%);
}

.postEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

/* Начальная фаза удаления элемента */
.postExit {
  opacity: 1;
  transform: translateX(0);
}

.postExitActive {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}
