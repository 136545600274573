.container {
  display: flex;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.tableBlock {
  width: 100%;
  position: static;
  z-index: 10;
}
.headBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}
.searchBlock {
  width: 40%;
}
.mainLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 45px);
}
.allCompaniesContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.selectedCompanyInfo {
  width: 100%;
}
.allCompaniesVisible,
.companyInfoVisible {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}
.allCompaniesHidden {
  transform: translateX(-150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.companyInfoHidden {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.locationBlock {
  display: flex;
  gap: 20px;
}
.backIcon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 2px;
}
.backBtn {
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.locationTitle {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(255, 255, 255, 0.6);
}
