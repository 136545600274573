.btn,
.btnDisabled {
  background-color: var(--primary-color);

  color: white;
  padding: 7px 10px;
  border-radius: 100px;
  transition: 0.5s;
}
.btn {
  cursor: pointer;
}
.btnDisabled {
  opacity: 0.5;
}
.btn:hover {
  background-color: var(--primary-hover) !important;
  /*   color: var(--primary-color) !important; */
}
.userBtn {
  color: white;
  padding: 7px 10px;
  border-radius: 100px;
  transition: 0.5s;
  cursor: pointer;
}
.userBtn:hover {
  background-color: transparent !important;
  color: var(--user-primary-color) !important;
}
