.container {
  padding: 20px;
  width: 100%;
}
.tabsBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0 10px 0;
  width: calc(100% - 0px);
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.activeTab {
  position: relative;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 18px;
}
.notActiveTab {
  cursor: pointer;
  font-weight: 600;
  color: #909090;
  font-size: 18px;
}
.specialityTabTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.tabActiveIndicator {
  position: absolute;
  bottom: -10px;
  height: 3px;
  background-color: var(--primary-color);
  width: 100%;
  left: 0;
  border-radius: 20px;
}
