.container {
  padding: 20px;
  background-color: #242424;
  color: white;
  border-radius: 20px;
}
.laoderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.actionsBlock {
  display: flex;
  gap: 20px;
}
.deletePostBtn {
  height: 40px;
  width: 100px;
  border-radius: 20px;
  background-color: rgb(255, 111, 111);
  border: none;
  cursor: pointer;
  color: white;
  transition: 0.3s;
  font-size: 16px;
}
.deletePostBtn:hover {
  background-color: rgb(207, 89, 89);
  transition: 0.3s;
}
.avatarNameBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.avatarBlock {
  display: flex;
  align-items: center;
}
.dateBlock span:nth-child(1) {
  margin-right: 10px;
}
.dateBlock span:nth-child(2) {
  color: var(--primary-color);
  font-weight: 600;
}
.myName {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.verifyIcon {
  margin-left: 10px;
}
.actionBlock {
  display: flex;
  margin-top: 10px;
}
.actionElement {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.actionIconBtn {
  cursor: pointer;
}
.actionIconBtn:hover .actionIcon path {
  stroke: var(--primary-color);
}
.actionIconBtn:hover .actionIconLike path {
  stroke: var(--primary-color);
}
.actionElement span {
  margin-left: 5px;
  margin-bottom: 5px;
}
.myselfLikeIcon path {
  fill: var(--primary-color);
  stroke: var(--primary-color);
}
.postActionBlock {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
}
.editIcon,
.deletIcon {
  margin-right: 10px;
}
.editIcon path,
.deletIcon path {
  transition: 0.3s;
}
.editButtonBlock,
.deleteButtonBlock {
  cursor: pointer;
  transition: 0.3s;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: calc(100% - 30px);
}
.deleteButtonBlock:hover,
.editButtonBlock:hover {
  background-color: #b6e0d0;
  color: #075736;
}

.deleteButtonBlock:hover .deletIcon path,
.editButtonBlock:hover .editIcon path {
  stroke: #075736;
}
.deleteButtonBlock {
  border-radius: 0 0 20px 20px;
}
.editButtonBlock {
  border-radius: 20px 20px 0 0;
}
.newPostBlock {
  width: 800px;
}
.newPostInputBlock {
  /*  margin: 10px 15px 0 15px; */
  width: 50%;
}
.newPostHeader {
  background-color: #b6e0d0;
  color: #075736;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  font-size: 18px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeIcon {
  cursor: pointer;
}
.closeIcon path {
  fill: #075736;
}
.newPostTitle {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  display: inline-block;
  margin-bottom: 2rem;
}
.textAreaNewPost {
  background-color: #e0e0e0;
  outline: none;
  border: none;
  width: calc(100% - 40px);
  font-size: 18px;
  line-height: 25px;
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
  resize: none;

  background-color: #f0f0f0;
}
.textAreaNewPost::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.createPostBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.smallNewPostBlock,
.bigNewPostBlock {
  background-color: #b6e0d0;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  cursor: pointer;
}
.bigNewPostBlock {
  height: 270px;
  padding: 20px;
  width: calc(100% - 62px);
}
.smallNewPostBlock {
  height: fit-content;
  padding: 20px;
  width: calc(100% - 62px);
  margin-bottom: 20px;
}
.smallNewPostBlock span,
.bigNewPostBlock span {
  width: 40px;
  height: 40px;
  font-size: 28px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  cursor: pointer;
}
.postContent {
  text-wrap: wrap;
  font-family: "Nunito Sans", sans-serif !important;
}
.adminAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.videosBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 300px;
  position: relative;
}
.postVideo {
  width: 100%;
  height: 300px;
  max-width: 1000px;
}
.tripleDotBtn {
  cursor: pointer;
}
.postActionMenu {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 10;
  left: -130px;
  top: 30px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.5);
  width: max-content;
}

.updatePostInput {
  width: calc(100% - 6px);
  resize: none;
  border: none;
  outline: none;
  margin: 10px 0 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.updateFilesBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.importVideoIcon,
.importImageIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.updateActionBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.saveUpdateBtn {
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}
.importFilesBtnBlock {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}
.showMoreBtn {
  color: var(--primary-color);
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .newPostBlock {
    width: 100vw;
  }
}
