.tableFooter {
  background-color: #313131;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  color: white !important;
}
.tableFooter svg path {
  fill: white;
}
.tableFooter option {
  color: white;
}
.paginationIcon path {
  fill: white !important;
}
.tableHead th {
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.avatarBlock {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}
.dateBlock {
  font-size: 16px;
}
.costBlock {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.completedStatus,
.missedStatus,
.scheduledStatus {
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 16px;
  width: fit-content;
}
.completedStatus {
  background-color: var(--secondary-color);
  color: var(--dark-green);
}
.missedStatus {
  background-color: var(--red);
  color: var(--dark-red);
}
.scheduledStatus {
  background-color: var(--yellow);
  color: var(--dark-yellow);
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vh;
}
.noSessions {
  color: white;
}
