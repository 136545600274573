@media screen and (max-width: 1300px) {
  .ps-menu-button {
    height: 40px !important;
  }
  .sub-menu {
    margin-left: 0 !important;
  }
  .sub-menu ul {
    position: absolute !important;
  }
}
