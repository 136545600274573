.container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chatHeader {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  align-items: center;
  color: white;
  margin: 0;
  text-align: center;
  padding: 20px 20px;
  border-radius: 20px;
}
.chatHeader img {
  cursor: pointer;
  transition: 0.3s;
}
.chatHeader img:hover {
  transform: scale(1.2);
}
.chatTitle {
  font-size: 24px;
  margin: 0;
}
.closeIcon {
  cursor: pointer;
}
.chatBody {
  flex: 1;
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.chatInputBlock {
  display: flex;
  align-items: flex-end;
  padding: 12px;
  position: relative;
  z-index: 0;
}
.chatInput {
  width: 100%;
  resize: none;
  display: flex;
  align-items: center;
  border: none;
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 20px 65px 20px 20px;
  outline: none;
  height: "auto";
  overflow-y: auto; /* Добавляем вертикальный скроллбар при необходимости */
  max-height: 300px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.chatInput::-webkit-scrollbar {
  width: 0.5em; /* Ширина скроллбара */
}

.chatInput::-webkit-scrollbar-thumb {
  background-color: transparent; /* Цвет ползунка скроллбара */
}

.sendButton {
  background-color: transparent;
  color: #fff;
  border: none;
  border-left: 1px solid #a6a6a6;
  padding-left: 5px;
  cursor: pointer;
}
.scrapIcon {
  cursor: pointer;
  margin-right: 10px;
}
.scrapIcon path {
  transition: 0.3s;
}
.scrapIcon:hover path {
  stroke: var(--primary-color);
}
.avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 1rem;
}
.actionChatBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 25px;
}
.filePreviewBlock {
  display: flex;
  padding: 0 12px;
}
.filePreview {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
.filePreview img {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}
.fileIcon {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
}

.deleteButton {
  position: absolute;
  top: -18px;
  right: -12px;
  padding: 2px 6px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  color: red;
  transition: background-color 0.3s ease-in-out;
}
.chatMobileHeader {
  display: none;
}
@media screen and (max-width: 1500px) {
  /*   .chatInput {
    padding: 10px 65px 10px 10px;
  } */
  .chatInput::placeholder {
    font-size: 10px !important;
    line-height: 18px;
    vertical-align: middle;
  }
  .actionChatBlock {
    bottom: 25px;
  }
}
@media screen and (max-width: 1300px) {
  .chatHeader {
    display: none;
  }
  .chatMobileHeader {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
    padding: 5px 10px;
  }
  .companionInfo {
    display: flex;
    align-items: center;
  }
  .closeIcon {
    width: 20px;
    height: 20px;
  }
  .closeIcon path {
    fill: black;
  }
  .companionInfo span {
    font-size: 14px;
    font-weight: 600;
  }
}
