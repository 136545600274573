.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow: auto;
}
.headBlock {
  display: flex;
  align-items: center;
}
.pageInfoTitleBlock {
  display: flex;
  flex-direction: column;
}
.pageInfoTitleBlock h1 {
  color: white;
  font-size: 32px;
  margin: 0 0 8px 0;
}
.pageInfoTitleBlock span {
  color: #727272;
  font-size: 14px;
}
.inviteSpecialistButtton {
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 32px;
  margin-left: 20px;
  cursor: pointer;
}

.filterBlock {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}
.filterIcon path {
  fill: white;
}
.searchInputBlock {
  width: 25%;
  position: relative;
  height: fit-content;
  margin-right: 50px;
}
.magnifierIcon {
  position: absolute;
  right: -5%;
  top: 25%;
}
.searchInput {
  width: 100%;
  background-color: #404040;
  padding: 10px 20px;
  border-radius: 20px;
  outline: none;
  color: white;
  border: none;
}
.filterButton {
  position: relative;
}
.circle {
  width: 40px;
  height: 40px;
  background-color: #404040;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: absolute;
  top: -20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rectangle {
  width: 500px;
  height: 400px;
  border-radius: 20px;
  background-color: #404040;
  transition: all 0.3s ease;
  position: absolute;
  top: -20px;
  z-index: 20;
  overflow: hidden;
}
.inputsFilterBlock {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
}

.countrySelect {
  background-color: white;
  border: none !important;
  outline: none;
  padding-bottom: 0;
}
.countrySelect button {
  border: none;
}
.countrySelect span {
  font-size: 16px;
}
.columnFilters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 360px;
}
.inputFilter {
  position: relative;
}
.inputFilter fieldset {
  border: none;
  outline: none;
}
.inputFilter div {
  background-color: rgb(113, 113, 113) !important;
  border-radius: 10px;
}
.inputFilter ul div {
  background-color: white !important;
}
.inputFilter ul div input {
  outline: none;
  border: none;
}
.inputFilter div div:nth-child(5) {
  width: 100% !important;
}
.inputFilter input::placeholder {
  color: black;
  opacity: 1;
}
.inputFilter button {
  display: flex;
  align-items: center;
  color: white;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Убираем внешние отступы для WebKit/Blink */
}
.rangeNumberInputsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.rangeNumberInputsBlock input {
  border: none;
  outline: none;
  width: 40%;
  background-color: transparent;
  border-radius: 10px;
  color: white;
  border-bottom: 1px solid var(--primary-color);
  text-align: center;
}
.rangeokButton {
  border: 1px solid transparent;
  color: white;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
}
.btnDisabled {
  border: 1px solid transparent;
  color: white;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.3;
}
.rangeokButton:hover {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.rangeTitleBlock span {
  font-size: 16px;
}
.rangeTitleBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: white;
}
.pagginationBlock {
  width: 100%;
  display: flex;
  justify-content: center;
}
.specialistCardContainer {
  width: 23%;
}
.closeIconBlock {
  position: absolute;
  top: 28%;
  right: 14%;

  cursor: pointer;
}

.closeIconBlock:hover .closeIcon path {
  fill: red;
}
.closeIcon {
  height: 10px;
  width: 10px;
}
.closeIcon path {
  fill: white;
}
.contentBlock {
  margin-top: 20px;
  width: 100%;
}
.verifiedStatusFilterBlock {
  position: absolute;
  right: 0;
  width: 180px;
  background-color: #313131;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  z-index: 99;
  top: 0;
}
.selectedStatusBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.chevronDownIcon,
.chevronUpIcon {
  transition: 0.5s;
  cursor: pointer;
}
.chevronUpIcon {
  transform: rotate(180deg);
}
.animHeight div {
  display: flex;
  flex-direction: column;
}
.animHeight span {
  color: white;
  width: 100%;
  cursor: pointer;
  padding: 3px 0;
}
.animHeight span:hover {
  background-color: #303a36;
}
.tableBlock {
  width: 100%;
  position: static;
  z-index: 10;
}
.specialistInfoBlock {
  transition: margin 0.3s ease-in-out;
  height: 0;
  width: 60%;
  background-color: white;
  margin-right: 1rem;
  overflow: hidden;
  margin-left: -65.7%;
  border-radius: 20px;
  background-color: #282828;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.contentBlock {
  display: flex;
  width: 100%;
}
.specialistInfoBlockActive {
  height: calc(100vh - 250px);
  margin-left: 1rem;
}
.chevronBackIcon {
  margin-right: 5px;
  margin-top: 5px;
}
.backBlock {
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.headNameBlock {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.headNameBlock span {
  color: white;
  font-size: 24px;
  margin-right: 5px;
}
.specialityTabsBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.activeChooseSpecTab,
.chooseSpecTab {
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.activeChooseSpecTab {
  background-color: var(--primary-color);
  color: white;
}
.chooseSpecTab {
  background-color: #727272;
  color: white;
}
.navigateSpecInfoBlock {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.underLine {
  position: absolute;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  width: 100%;
}
.navElement {
  color: white;
  padding: 0 2px 10px 0;
  border-bottom: 2px solid transparent;
  margin-left: 1rem;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.selectNavElement {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  position: static;
  z-index: 1;
}

.personInfoBlock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.infoElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  margin-bottom: 20px;
}
.infoElement span {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.infoElement span:nth-child(1) {
  color: white;
}
.infoElement span:nth-child(2) {
  color: #727272;
}
.personInfoTitle {
  color: white;
  margin: 0;
}

.complaintsBlock {
  overflow: auto;
  max-height: calc(100% - 130px);
  padding-right: 8px;
}
.complaintsBlock h2 {
  color: #303a36;
  font-size: 32px;
  text-align: center;
}
.noSpecialistBlock {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noSpecialistBlock h1 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 42px;
}
.viewAllBtn {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}
.personInfoTitleBlock {
  display: flex;
  align-items: baseline;
  margin: 20px 0;
}
.profileContainer {
  width: 90vw;
  height: 90vh;
  overflow: auto;
}
