.container {
  background-color: #1d1d1d;
  display: flex;
  height: 100svh;
}
.mainContent {
  width: 100%;
  display: flex;
  justify-content: center;
  /*   align-items: center; */
  overflow: hidden;
}
.sideBarMenu {
  display: block;
}
@media screen and (max-width: 768px) {
  .sideBarMenu {
    display: none;
  }
  .mainContent {
    width: 100vw;
    min-height: calc(100vh - 73px);
    height: auto;
    align-items: flex-start;
  }
}
