.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  border-radius: 20px;
  padding: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}
.cardTitle {
  color: #696969;
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  text-align: center;
}
.cardvalue {
  text-align: center;
  color: white;
  font-size: 30px;
}
.unitTitle {
  color: white;
}
