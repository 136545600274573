.nameColumn {
  color: var(--primary-color) !important;
}
.ownerBlock {
  display: flex;
  color: white;
  align-items: center;
}
.clientsColumn,
.specColumn,
.programsColumn {
  color: white !important;
  text-align: start;
}
.tableFooter {
  background-color: #313131;
  width: 100%;
}
.tableFooter svg path {
  fill: white;
}
.tableFooter option {
  color: black;
}
.tableHead th {
  font-weight: 600;
  border-bottom: 1px solid black;
}
