.container {
  width: 100%;
  color: white;
}
.tabsBlock {
  display: flex;
  margin-top: 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  width: fit-content;
  padding: 0 0 15px 0;
  margin-bottom: 20px;
}
.activeTab,
.notActiveTab {
  cursor: pointer;
  width: 100px;
  text-align: center;
}
.activeTab {
  position: relative;
}
.activeTab span:nth-child(1) {
  color: var(--primary-color);
  text-align: center;
  display: block;
}
.activeTab span:nth-child(2) {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -17px;
  background-color: var(--primary-color);
  left: 0;
}
.notActiveTab span:nth-child(1) {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  display: block;
}
