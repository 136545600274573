.card {
  background-color: #131313;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50vw;
  margin: 16px;
  max-height: 80vh;
  overflow-y: auto;
  color: white;
}

.customComment {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 12px;
  max-height: 6em; /* Ограничение по высоте */
  overflow: hidden;
}

.staticComments {
  list-style: none;
  padding: 0;
  margin: 0 0 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.staticComment {
  background-color: #252525;
  border-radius: 20px;
  padding: 8px;
  margin-bottom: 6px;
  font-size: 14px;
  color: var(--primary-color);
}

.moreComments {
  font-size: 14px;
  color: #1976d2;
  margin: 0;
}

.showMoreButton {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.showMoreButton:hover {
  text-decoration: underline;
}
.ratingBlock {
  display: flex;
  align-items: center;
}
.ratingBlock span {
  font-size: 18px;
  color: var(--primary-color);
  margin-top: 3px;
  font-weight: 600;
  margin-left: 7px;
}
@media screen and (max-width: 768px) {
  .card {
    width: calc(100vw - 80px);
    margin: 0;
  }
}
