.cardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nameBlock,
.programBlock {
  width: 30%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.programBlock {
  display: flex;
  align-items: center;
}
.programBlock div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.positionBlock,
.completedBlock {
  width: 20%;
}
.nameBlock {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
.nameTitleBlock {
  display: flex;
  flex-direction: column;
}
.nameTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.positionBlock {
  color: white;
}
.completedBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.completedBlock span {
  font-size: 16px;
}
.tripleDotIconBlock {
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  padding: 3px;
}
.tripleDotIconBlock:hover img {
  transform: scale(1.2);
}
.emailTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.deletIcon path {
  transition: 0.3s;
}

.deleteButtonBlock {
  cursor: pointer;
  transition: 0.3s;
}
.editButtonBlock:hover {
  transform: scale(1.2);
}

.deleteButtonBlock:hover {
  transform: scale(1.2);
}
.deleteButtonBlock:hover .deletIcon path {
  stroke: #e74c3c;
}
.settingIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 1s;
}
.settingIcon:hover {
  transform: rotate(360deg);
}
.removeButton {
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
  background-color: #e74c3c;
  border: 1px solid #e74c3c;
  border-radius: 20px;
  height: 35px;
  width: 100px;
  cursor: pointer;
  transition: 0.3s;
}
.removeButton:hover {
  background-color: white;
  color: #e74c3c;
}

.programElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 10px 0;
}
.programElement span {
  font-size: 18px;
}
.programTitleEl {
  margin-right: 5px;
  width: fit-content !important;
}
.mobileDeletBlock {
  display: none;
}
.programsModalContainer {
  width: 500px;
}

@media screen and (max-width: 768px) {
  .nameBlock,
  .programBlock {
    width: 50%;
  }
  .positionBlock,
  .completedBlock {
    display: none;
  }
  .nameTitle {
    text-wrap: balance;
  }
  .mobileDeletBlock {
    display: flex;
    margin-left: 20px;
    margin-top: 2px;
  }
  .programsModalContainer {
    width: 85vw;
  }
}
