.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.textArea {
  min-height: 50px;
  width: calc(100% - 40px);
  border-radius: 20px;
  background-color: rgb(46, 46, 46);
  resize: none;
  border: none;
  outline: none;
  padding: 20px;
  caret-color: white;
  color: white;
}
.textArea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.inputsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.uplaodIcon {
  width: 40px;
  height: 40px;
}
.uploadIconBlock {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.iconBlock {
  position: relative;
}
.icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}
.iconActionBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.iconActionBtn {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
