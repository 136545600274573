.container {
  margin-top: 20px;
}
.nameAvatarBlock {
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
}
.commentTextBlock {
  color: white;
  margin: 10px 0;
  background-color: #313131;
  padding: 10px 20px;
  font-size: 14px;
}
.dateBlock {
  text-align: end;
  color: #696969;
  font-size: 14px;
}
