.messageContainer {
  display: flex;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.systemMessage {
  background-color: #ebebeb;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  margin-bottom: 8px;
  align-self: center;
  max-width: 70%;
}
.userMessage {
  background-color: var(--primary-color);
  color: #fff;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 8px;
  align-self: flex-end;
  max-width: 70%;
}

.otherMessage {
  background-color: #ebebeb;
  color: #000;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 8px;
  align-self: flex-start;
  max-width: 70%;
}
.sessionMessage {
  background-color: #ffffff;
  color: #000;
  padding: 15px;
  margin-bottom: 8px;
  border-radius: 20px;
  align-self: center;
  max-width: 70%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}
.messageText {
  margin: 0;
  max-width: 100%;
  min-width: 100px;
  word-break: break-word;
}
.file {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.file:hover {
  transform: scale(1.1);
}
.modalImageBlock {
  height: 100%;
  width: 100%;
}
.modalImage {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  border-radius: 20px;
}
.fileBlock {
  width: 92%;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  padding: 5px 10px;
}

.fileMessage,
.fileMessageOther {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  transition: 0.3s;
}
.fileMessageOther path {
  fill: var(--primary-color);
}
.downloadIcon {
  fill: #fff;
  stroke: #fff;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.downloadIcon:hover {
  transform: scale(1.2);
}
.fileMessage path {
  fill: #fff;
}
.fileBlock div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.emptyImage {
  width: 100px;
  height: 100px;
  background-color: #fff;
}
.loaderBlock {
  display: flex;
  justify-content: center;
  width: 100%;
}
.fileInfoBlock,
.fileInfoBlockOther {
  display: flex;
  flex-direction: column;
  width: 90%;
  /*   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.fileInfoBlockOther span,
.fileInfoBlock span {
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileInfoBlock span {
  color: white;
}
.fileInfoBlockOther span {
  color: var(--primary-color);
}
.fileBlock:hover .fileMessage {
  transform: scale(1.1);
}
.fileBlock:hover .fileMessageOther {
  transform: scale(1.1);
}
.messageTimeTitle {
  display: flex;
  width: 100%;
  opacity: 0.7;
}
@media screen and (max-width: 768px) {
  .messageText {
    font-weight: 600;
    text-align: center;
  }
  .systemMessage {
    max-width: 100%;
  }
}
