.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  box-sizing: border-box;
}
.mainSpecInfoBlock {
  display: flex;
  align-items: center;
}
.mainSpecInfoBlock,
.specalizationTitleBlock {
  width: 25%;
}
.priceActionBlock {
  width: 25%;
  padding: 0 10px;
}
.indicator {
  position: absolute;
  top: 0;
  right: -5px;
  width: 10px;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 10px 0 0 10px;
}
.specalizationTitleBlock {
  display: flex;
  font-size: 16px;
  color: white;
  gap: 5px;
}
.mainSpecTitleInfo span:nth-child(1) {
  font-size: 16px;
  margin-right: 10px;
}
.specalizationTitleBlock span:nth-child(1) {
  font-size: 16px;
  color: white;
}
.priceActionBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prcieBlock {
  display: flex;
  flex-direction: column;
}
.prcieBlock span:nth-child(1) {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.prcieBlock span:nth-child(2) {
  font-size: 14px;
  color: #727272;
  text-decoration: line-through;
}
.starIcon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
.bookingBlock {
  display: flex;
  justify-content: flex-start;
}
.deleteBtn {
  cursor: pointer;
}
.deleteBtn:hover .deleteIcon path {
  stroke: #e55a5a;
}
.activeSpecialization,
.notActiveSpecialiation {
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
}
.activeSpecialization {
  background-color: var(--primary-color);
}
.notActiveSpecialiation {
  background-color: #727272;
}
@media screen and (max-width: 768px) {
  .languagesBlock,
  .specalizationTitleBlock {
    display: none;
  }
  .mainSpecInfoBlock {
    width: 40%;
  }
  .prcieBlock,
  .bookingBlock {
    width: 30%;
  }
}
