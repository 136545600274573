.container {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  width: calc(100%);
  border-radius: 20px;
  box-sizing: border-box;
}
.locationTitleBlock {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.locationTitleBlock span {
  font-size: 18px;
  color: rgba(32, 34, 36, 0.5);
}
.chevronIcon {
  margin: 0 7px;
  transform: rotate(90deg);
}
.chevronIcon path {
  fill: rgba(32, 34, 36, 0.5);
}
.employersTableContainer {
  width: 100%;
}
.rightInfoBlock {
  width: 30%;
}
.filterBlock {
  display: flex;
  align-items: center;
}
.headBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.actionBlock {
  display: flex;
  gap: 10px;
}
.filterTitle {
  font-size: 18px;
  margin-right: 2rem;
}
.multiSelectBlock {
  width: 250px;
  margin-right: 1rem;
}
.multiSelectBlock fieldset {
  border: none;
}
.searchInputBlock {
  margin-right: 20px;
  position: relative;
  min-width: 300px;
}
.magnifierIcon {
  position: absolute;
  right: 3%;
  top: 25%;
}
.filterButton {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(224, 224, 224);
  border-radius: 50%;
  cursor: pointer;
}
.tableBlock {
  overflow: auto;
  height: calc(100vh - 330px);
  border-radius: 20px;
}
.lableTableBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 2rem;
}
.lableTableBlock span {
  font-size: 16px;
}
.employeeCardContainer {
  padding: 10px;
}
.selectInputBlock,
.selectFilterBlock {
  display: flex;
  align-items: center;
}
.nameTableLable,
.programTableLable {
  width: 30%;
}
.roleTableLable,
.emailTableLable {
  width: 20%;
}
@media screen and (max-width: 1700px) {
  .employersTableContainer {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .headBlock {
    flex-direction: column;
    align-items: flex-start;
  }
  .filterBlock {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 900px) {
  .filterBlock {
    flex-direction: column;
    align-items: start;
  }
  .searchInputBlock {
    width: calc(100% - 40px);
    margin-bottom: 20px;
    margin-right: 0;
  }
  .selectFilterBlock {
    width: 100%;
  }
  .selectInputBlock {
    width: 80%;
  }
  .multiSelectBlock {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
    width: 100%;
    border-radius: 0;
    min-height: calc(100vh - 73px);
  }
  .employersTableContainer {
    width: 100%;
  }
  .filterBlock {
    flex-direction: column;
    align-items: start;
  }
  .selectFilterBlock {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
  .selectInputBlock {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .searchInputBlock {
    margin-right: 0;
    width: calc(100%);
    margin-bottom: 1rem;
  }
  .multiSelectBlock {
    width: 45%;
    margin-right: 0;
  }
  .roleTableLable,
  .emailTableLable {
    display: none;
  }
  .nameTableLabl,
  .programTableLable {
    width: 50%;
  }
  .employeeCardContainer {
    padding: 5px;
  }
}
