.container {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pricingSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.pricingSection h4,
.pricingSection h3 {
  margin: 0;
}
.pricingSection h4 {
  color: rgba(255, 255, 255, 0.5);
}
.flexGap20Block {
  display: flex;
  align-items: center;
  gap: 20px;
}
.newRotationBtn {
  cursor: pointer;
}
.actionIcon {
  margin-top: 4px;
}
.actionIcon path {
  stroke: #696969;
}
.actionIcon:hover path {
  stroke: var(--primary-color);
}
.selectFileBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
.csvIcon path {
  fill: white;
}
.selectFileBtn:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.selectFileBtn:hover .csvIcon path {
  fill: var(--primary-color);
}
.selectedFileTitle {
  color: white;
}
.newRotationBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 300px;
  color: white;
}
.pricingInfoBlock {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background-color: rgb(46, 46, 46);
  width: fit-content;
  gap: 20px;
}
.dateValue {
  color: var(--primary-color);
  font-weight: 600;
}
.inputLable {
  margin-bottom: 10px;
  display: block;
}
