.container {
  width: calc(100% - 40px);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
}
.selectedChat {
  background-color: #353535;
}
.selectIndicator {
  position: absolute;
  height: 100%;
  width: 5px;
  background-color: var(--primary-color);
  right: 0;
  border-radius: 5px;
}
.container:hover {
  background-color: #353535;
}
.leftContactInfo {
  display: flex;
  align-items: center;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.nameTitleBlock {
  display: flex;
  flex-direction: column;
}
.nameTitleBlock span:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.nameTitleBlock span:nth-child(2) {
  width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rightContactBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.rightContactBlock span:nth-child(1) {
  font-size: 14px;
  color: white;
  margin-bottom: 5px;
  text-wrap: nowrap;
}
.newMessageIndicator {
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50%;
}
.emptyAvatarBlock {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 24px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 10px;
}
@media screen and (max-width: 1700px) {
  .nameTitleBlock span:nth-child(2) {
    width: 11em;
  }
}
