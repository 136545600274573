.container {
  color: white;
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 90vh;
  overflow-y: auto;
  padding-right: 10px;
}
.loaderContainer {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headTitle {
  text-align: center;
  margin: 0 0 20px 0;
}
.descriptionBlock {
  display: flex;
  flex-direction: column;
}
.textArea {
  background-color: rgb(46, 46, 46);
  border-radius: 20px;
  outline: none;
  border: none;
  color: white;
  resize: none;
  padding: 20px;
}
.inputsBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}
.endDateBlock,
.privateBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.endDateBlock input {
  background-color: rgb(46, 46, 46);
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 10px 10px;
  color: white;
}
.actionBlock {
  display: flex;
  justify-content: center;
}
.uploadVideoBtn {
  color: white;
  cursor: pointer;
  padding: 6px 20px;
  background-color: var(--primary-color);
  border-radius: 20px;
  width: fit-content;
  margin-top: 20px;
}
.video {
  max-height: 200px;
  width: 100%;
}
.videoBlock {
  display: flex;
  flex-direction: column;
}
.projectTypeBlock {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
