.container {
  height: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  background-color: #242424;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  color: white;
}

.contactsBlock {
  width: calc(30%);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  /*  padding-right: 20px; */
}
.contactsListBlock {
  overflow: auto;
  height: calc(100% - 75px);
}
.messsagesBlock {
  width: calc(70%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchBlock {
  position: relative;
  width: calc(100% - 40px);
  padding: 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 74px;
  display: flex;
  align-items: center;
}
.searchIcon {
  position: absolute;
  top: 35%;
  right: 7%;
}
.messsagesBlockNotSelect {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  gap: 20px;
  color: #92cab3;
  font-weight: 600;
}

.chatHeaderBlock img,
.chatHeaderBlock span {
  cursor: pointer;
}
.chatHistoryBlock {
  height: 100vh;
  overflow: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;

  /* justify-content: flex-end; */
}

.chatHistory {
  display: flex;
  flex-direction: column;
}
.dateSeparator {
  display: flex;
  width: 100%;
  justify-content: center;
}
.dateSeparator span {
  color: white;
  background-color: var(--primary-color);
  border-radius: 90px;
  padding: 5px 10px;
  margin: 2rem 0;
}
.chatHeaderBlock {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 74px;
}
.chatHeaderBlock img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
.chatHeaderBlock span {
  font-size: 18px;
  font-weight: 600;
}
.chatInputBlock {
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 0;
  padding: 0 20px 20px 20px;
}
.chatInput {
  width: 100%;
  resize: none;
  display: flex;
  align-items: center;
  border: none;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 20px 65px 20px 20px;
  outline: none;
  height: "auto";
  overflow-y: auto; /* Добавляем вертикальный скроллбар при необходимости */
  max-height: 300px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.chatInput::-webkit-scrollbar {
  width: 0.5em; /* Ширина скроллбара */
}

.chatInput::-webkit-scrollbar-thumb {
  background-color: transparent; /* Цвет ползунка скроллбара */
}
.sendButton {
  background-color: transparent;
  color: #fff;
  border: none;
  border-left: 1px solid #a6a6a6;
  padding-left: 5px;
  cursor: pointer;
}
.scrapIcon {
  cursor: pointer;
  margin-right: 10px;
}
.scrapIcon path {
  transition: 0.3s;
}
.scrapIcon:hover path {
  stroke: var(--primary-color);
}
.avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 1rem;
}
.actionChatBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 30px;
  bottom: 32px;
}
.filePreviewBlock {
  display: flex;
  padding: 0 12px;
  position: absolute;
  top: -60%;
  left: 0;
}
.filePreview {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
.filePreview img {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}
.fileIcon {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
}

.deleteButton {
  position: absolute;
  top: -18px;
  right: -12px;
  padding: 2px 6px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  color: red;
  transition: background-color 0.3s ease-in-out;
}
.chevronBack {
  display: none;
}
@media screen and (max-width: 1700px) {
  .chatHistoryBlock {
    max-height: 60vh;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    padding: 10px;
  }
}
@media screen and (max-width: 820px) {
  .container {
    padding: 0 10px 10px 10px;
    width: 100%;
    border-radius: 0;
    min-height: calc(100vh - 83px);
    height: auto;
    position: relative;
  }
  .contactsBlock {
    border-right: none;
    padding-right: 0;
    width: 100%;
  }
  .mobileMessagesBlockHidden {
    transform: translateX(150%);
    position: absolute;
    transition: transform 0.5s ease;
  }
  .mobileContactsBlockHidden {
    transform: translateX(-150%);
    position: absolute;
    transition: transform 0.5s ease;
    background-color: white;
  }
  .mobileContactsBlockVisible {
    transform: translateX(0);
    transition: transform 0.5s ease;
    background-color: white;
  }
  .mobileMessagesBlockVisible {
    transform: translateX(0);
    transition: transform 0.5s ease;
  }
  .messsagesBlock {
    width: 100%;
    padding: 0;
  }

  .chevronBack {
    display: block;
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
    margin-right: 10px;
  }
  .chevronBack path {
    fill: var(--primary-color);
  }
  .messsagesBlockNotSelect {
    display: none;
  }
  .chatHeaderBlock {
    padding: 0 10px 10px 10px;
  }
}
