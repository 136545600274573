.container {
  position: relative;
}
.input {
  width: calc(100% - 40px);
  outline: none;
  border: none;
  color: white;
  border-radius: 20px;
}
.input::placeholder {
  color: #c1c1c1;
  letter-spacing: 1px;
}
.searchIcon {
  position: absolute;
  right: 20px;
}
.lable {
  color: white;
  margin-bottom: 10px;
  display: block;
}
