.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  flex: 1;
  height: 100%;
}
.contentBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: calc(100% - 50px);
  flex: none;
}
.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blockLable {
  opacity: 0.7;
}
.textInfoValue {
  color: var(--primary-color);
}
.verifiedStatus,
.unverifiedStatus {
  padding: 5px 8px;
  border-radius: 10px;
  width: fit-content;
}
.verifiedStatus {
  color: #3fb082;
  background-color: rgba(63, 176, 131, 0.25);
}
.unverifiedStatus {
  color: #e34a4a;
  background-color: rgba(227, 74, 74, 0.25);
}
.badgeIcon {
  max-height: 200px;
  width: auto;
  object-fit: contain;
}
.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.chevronBackIcon {
  transform: rotate(90deg);
}
.chevronBackIcon path {
  stroke: var(--primary-color);
  stroke-width: 1px;
}
.backBlock {
  display: flex;
  gap: 5px;
  color: var(--primary-color);
  cursor: pointer;
  align-items: center;
}
.actionBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.editBtn,
.deleteBtn {
  cursor: pointer;
}
.editBtn:hover .editIcon path {
  stroke: rgb(255, 208, 0);
}
.deleteBtn {
  margin-bottom: 2px;
}
.deleteBtn:hover .deleteIcon path {
  stroke: red;
}
