.container {
  width: 450px;
  max-height: 85vh;
  overflow-y: auto;
}
.loaderContainer {
  width: 450px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dateBlock {
  margin-top: 20px;
  margin-bottom: 20px;
}
.inputBlock {
  margin-top: 20px;
}
.dateInputBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.dateInput {
  width: 50%;
}
.addCertificateBtn {
  border: 1px dashed #dadada;
  padding: 0 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 10px;
  cursor: pointer;
  color: #909090;
}
.addCertificateBtn span {
  margin-bottom: 3px;
  font-weight: 600;
}
.addCertificateBtn:hover {
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
}
.addCertificateBtn:hover .plusIcon path {
  stroke: var(--primary-color);
}
.updateAvatarBtn {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.updateAvatarBtn img {
  margin-right: 15px;
}
.updateAvatarBtn span {
  font-size: 18px;
  font-weight: 600;
}
.headLable {
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.secondLable {
  display: block;
  color: #909090;
  margin-bottom: 10px;
}
.newFederationBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}
.badgeIcon {
  width: 40px;
  height: 40px;
}
.uploadFilesActionBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.uploadCertificateBlock {
  margin-bottom: 20px;
}
.selectFinishedStudyBlock {
  display: inline-block;
}

.activeTab,
.notActiveTab {
  position: relative;
  font-weight: 600;
}
.tabIndicator {
  position: absolute;
  bottom: -10px;
  height: 3px;
  background-color: var(--primary-color);
  width: 100%;
  left: 0;
  border-radius: 20px;
}
.langsBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.activeTab {
  color: var(--primary-color);
}
.notActiveTab {
  color: white;
  cursor: pointer;
}
.courseAccrListBlock {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.courseAccrElement,
.addCourseAccrBtn {
  width: calc(50% - 30px);
  background-color: #222222;
  border-radius: 20px;
  padding: 10px;
}
.courseAccrElement {
  position: relative;
}
.deleteBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.deleteBtn:hover .closeIcon path {
  stroke: red;
}
.addCourseAccrBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plusIconCourse {
  width: 70px;
  height: 70px;
}
.addCourseAccrBtn:hover .plusIconCourse path {
  stroke: var(--primary-color);
}
.addAccrBtnBlock {
  display: flex;
  justify-content: center;
}
.addAccrBtn {
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addAccrBtn:hover {
  border: 1px solid var(--primary-color);
}
.lableAccrBlock {
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}
.lableAccrBlock span:nth-child(2) {
  cursor: pointer;
}
.deleteAccrIcon {
  width: 12px;
  height: 12px;
}
.deleteAccrIcon path {
  stroke: red;
  fill: red;
}
.accrList {
  max-height: 200px;
  overflow-y: auto;
}
