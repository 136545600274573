.container {
  width: 500px;
  color: white;
}
.newCompanyTitle {
  text-align: center;
}
.createBtnBlock {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.selectOwnerBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
}
.searchDescription {
  opacity: 0.6;
  padding-bottom: 10px;
}
.searchResultBlock {
  position: absolute;
  top: 80%;
  background-color: rgb(46, 46, 46);
  border-radius: 20px;

  z-index: 999;
  max-height: 250px;
  overflow: auto;
}
.userBlock,
.lastUserBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 15px;
  cursor: pointer;
}
.userBlock {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.userBlock:hover,
.lastUserBlock:hover {
  background-color: rgb(34, 34, 34);
}
.selectedUserBlock {
  background-color: rgb(46, 46, 46);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px 20px;
}
.selectedUser {
  display: flex;
  align-items: center;
  gap: 10px;
}
.closeIcon {
  cursor: pointer;
}
.closeIcon path {
  transition: 0.3s;
}
.closeIcon:hover path {
  fill: red;
}
.cancelBtn {
  background-color: rgb(46, 46, 46);
  width: 200px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 18px;
  transition: 0.3s;
}
.cancelBtn:hover {
  background-color: rgb(34, 34, 34);
}
