#burger-container {
  display: flex;
  justify-content: flex-end;
  height: 50px;
}

#trigger,
#burger,
#burger:before,
#burger:after {
  position: absolute;
  top: 25px;
  right: 25px;
  background: #696969;
  width: 28.5px;
  height: 3px;
  transition: 0.2s ease;
  cursor: pointer;
  z-index: 1;
  border-radius: 30px;
}

#trigger {
  height: 25px;
  background: none;
}

#burger:before {
  content: " ";
  top: 10px;
  left: 0;
  width: 15px;
}

#burger:after {
  content: " ";
  top: 20px;
  left: 0;
  opacity: 0;
}

#menu-toggle:not(:checked) + #trigger + #burger {
  top: 35px;
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

#menu-toggle:not(:checked) + #trigger + #burger:before {
  width: 15px;
  top: -2px;
  left: 18px;
  transform: rotate(45deg) translateX(-5px);
  transition: transform 0.2s ease;
}

#menu-toggle:not(:checked) + #trigger + #burger:after {
  width: 15px;
  top: 2px;
  left: 18px;
  opacity: 1;
  transform: rotate(-45deg) translateX(-5px);
  transition: transform 0.2s ease;
}

#menu {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 110px;
  height: 110px;
  background-color: #fff;
  border-bottom-right-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  animation: not-checked-anim 0.2s both;
  transition: 0.2s;
}

#menu-toggle:checked + #trigger + #burger + #menu {
  animation: checked-anim 1s ease both;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}
