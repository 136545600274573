.container {
  width: 100%;
}
.backBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--primary-color);
}
.tableBlock {
  margin-top: 30px;
  width: 100%;
}
.headTableBlock {
  background-color: rgb(46, 46, 46);
  padding: 10px 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.tabsBlock {
  display: flex;
}
.specTab {
  border-radius: 20px 0 0 20px;
}
.clientsTab {
  border-radius: 0 20px 20px 0;
}
.specTab,
.clientsTab {
  padding: 5px 10px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeTab {
  background-color: var(--primary-color);
  box-shadow: inset 0px -2px 10px rgba(0, 0, 0, 0.6);
}
.notActivetab {
  cursor: pointer;
  background-color: rgb(77, 77, 77);
}
.tableTitleBlock {
  font-size: 24px;
}
.descriptionBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.descriptionBlock span:nth-child(1) {
  font-size: 18px;
  margin-bottom: 10px;
}
.discountValue {
  color: var(--primary-color);
  font-weight: 600;
}
.discoutnZero {
  color: #e55a5a;
  font-weight: 600;
}
.priceBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}
.specialistsTableLableBlock,
.clientsTableLableBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(46, 46, 46);
}

.specialistsTableLableBlock span,
.clientsTableLableBlock span {
  width: 25%;
}
.clientsListBlock {
  width: calc(100% - 20px);
}
.specialistsListBlock {
  width: 100%;
}
.inviteSpecModal {
  color: white;
  width: 900px;
}
.inviteHeadTitle {
  text-align: center;
  margin: 0 0 20px 0;
}
.inviteSpecialistsList {
  width: calc(100%);
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.inviteSpecElement {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.noDataBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}
.cancelBtn {
  background-color: rgb(46, 46, 46);
  width: 200px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 16px;
  transition: 0.3s;
}
.cancelBtn:hover {
  background-color: rgb(34, 34, 34);
}
.actionBlock {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.loaderContainer {
  width: 700px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
