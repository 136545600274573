.container {
  display: flex;
  width: 100%;
  max-height: 100svh;
  padding: 30px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  color: white;
}
.tableBlock {
  width: 100%;
  position: static;
  z-index: 10;
}
.allSubscriptionsContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectedSubscriptionInfo {
  width: 100%;
}
.allCompaniesVisible,
.allSubscriptionsVisible {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}
.allSubscriptionsHidden {
  transform: translateX(-150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.subscriptionHidden {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.noSubBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}
