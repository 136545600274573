.container {
  width: 500px;
  color: white;
}
.loaderContainer {
  width: 500px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectSpecBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
}
.searchDescription {
  opacity: 0.6;
  padding-bottom: 10px;
}
.searchResultBlock {
  position: absolute;
  top: 110%;
  background-color: rgb(46, 46, 46);
  border-radius: 20px;
  z-index: 999;
  max-height: 200px;
  overflow: auto;
  width: 100%;
}
.userBlock,
.lastUserBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 15px;
  cursor: pointer;
}
.userBlock {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.userBlock:hover,
.lastUserBlock:hover {
  background-color: rgb(34, 34, 34);
}
.selectedUserBlock {
  background-color: rgb(46, 46, 46);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px 20px;
}
.cancelBtn {
  background-color: rgb(46, 46, 46);
  width: 200px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  color: white;
  font-size: 16px;
  transition: 0.3s;
}
.cancelBtn:hover {
  background-color: rgb(34, 34, 34);
}
.actionBlock {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.headTitle {
  text-align: center;
  margin: 0 0 20px 0;
}
.selectedSpecialistsBlock {
  height: 200px;
  background-color: rgb(46, 46, 46);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: auto;
}
.noSpecTitle {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  margin-top: 90px;
  display: block;
}
.selectedUserBlock {
  background-color: rgb(46, 46, 46);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.selectedUser {
  display: flex;
  align-items: center;
  gap: 10px;
}
.closeIcon {
  cursor: pointer;
}
.closeIcon path {
  transition: 0.3s;
}
.closeIcon:hover path {
  fill: red;
}
