.container {
  cursor: pointer;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  border-radius: 50%;
  object-fit: cover;
}
.emptyAvatarBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: white;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.modalAvatar {
  max-height: 90vh;
}
