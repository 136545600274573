.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  padding: 20px;
  cursor: pointer;
}
.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.nameBlock,
.clientsLimitBlock,
.specLimitBlock,
.discountBlock,
.isPrivateBlock,
.endDateBlock {
  width: 16.6%;
}
.privateBlock {
  background-color: var(--primary-color);
  color: #003a24;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
}
.notPrivateBlock {
  background-color: rgba(229, 90, 90, 0.2);
  color: #e55a5a;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
}
.programActionBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.endDateBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deleteBtn:hover .deleteIcon path {
  stroke: #e55a5a;
}
.editBtn:hover .editIcon path {
  stroke: #fac748;
}
