.container {
  position: relative;
}
.lableTableBlock {
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 2rem;
  padding: 0 20px;
  position: relative;
}
.lableTableBlock span {
  font-size: 16px;
  width: 16.6%;
}
.tableBlock {
  overflow: auto;
  height: calc(100vh - 300px);
  border-radius: 20px;
}
.noProgramsBlock {
  height: calc(100vh - 300px);
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}
.allProgramsBlockVisibleVisible,
.programInfoBlockVisible {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}
.allProgramsBlockHidden {
  transform: translateX(-150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.programInfoBlockHidden {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
  position: absolute;
}
.allProgramsBlock {
  overflow: hidden;
}
.programInfoBlock {
  width: 100%;
}
