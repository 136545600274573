.mainContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tableBlock {
  z-index: 1;
  position: relative;
  width: 100%;
}

.sessionInfoBlock {
  transition: margin 0.3s ease-in-out;
  height: 0;
  width: 50%;
  overflow: hidden;
  margin-left: -65.7%;
  border-radius: 20px;
  background-color: #242424;
  padding: 20px;
  color: white;
}
.sessionInfoBlockActive {
  height: calc(100vh - 270px);
  margin-left: 1rem;
  overflow-y: auto;
  /*   filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); */
}
.infoRow {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.infoElement {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.infoLable {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.infoValue {
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
}
.subjectBlock {
  display: flex;
  flex-direction: column;
}
.blockLable {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.rowInfoElement {
  display: flex;
  gap: 10px;
}
.infoRowValue {
  font-weight: 600;
  color: var(--primary-color);
}
.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.buttonsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.backTitle {
  color: var(--primary-color);
  margin-left: 5px;
}
.flexRowBlock {
  display: flex;
  gap: 20px;
}
.durationElement {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.durationBlock {
  text-align: center;
}
.actionBlock {
  display: flex;
  gap: 20px;
}
.actionBtn {
  border: none;
  padding: 7px 20px;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  background-color: #6d6d6d;
  font-size: 600;
}
.actionBtn:hover {
  background-color: var(--primary-color);
  color: white;
}
.participantBlock {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.participantName {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}

.logsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logItem {
  margin-bottom: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #ffffff;
}

.logItem:last-child {
  border-bottom: none;
}

.flexRowBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.label {
  font-weight: bold;
}

.time {
  color: var(--primary-color);
}
