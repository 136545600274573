.container,
.loaderContainer {
  width: 80vw;
  height: 90vh;
  color: white;
  overflow: auto;
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentBlock {
  display: flex;
}
.header {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectLangBlock {
  width: 300px;
}
.leftBlock,
.rightBlock {
  padding: 20px;
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.leftBlock {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.lable {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}
.textArea {
  min-height: 50px;
  width: calc(100% - 20px);
  border-radius: 20px;
  background-color: rgb(46, 46, 46);
  resize: none;
  border: none;
  outline: none;
  padding: 10px;
  caret-color: white;
  color: white;
}
.periodDiscountBlock {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.periodDiscountBlock span {
  width: 100px;
}
.periodDiscountInput {
  width: 200px;
}
.discountForList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.discountForElement {
  width: 28%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  /*  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2); */
}
.deleteDiscountIcon {
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
}
.deleteDiscountIcon path {
  stroke: rgb(206, 4, 4);
}
.plusDiscountIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  align-self: center;
  margin-left: 10px;
}
.plusDiscountIcon:hover path {
  fill: var(--primary-color);
}
.rightBlock {
  display: flex;
  flex-direction: column;
}
.rightBlock h2 {
  text-align: center;
  width: 100%;
  margin: 0 0 20px 0;
}
.openCardBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.chevronBtn,
.chevronUpBtn {
  width: 30px;
  height: 30px;
  transition: 0.3s;
  cursor: pointer;
}
.chevronBtn path,
.chevronUpBtn path {
  stroke: var(--primary-color);
}
.chevronUpBtn {
  transform: rotate(180deg);
}
.infoCardElement {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
}
.featureList {
  margin-top: 30px;
  width: 80%;
  align-self: center;
}
.plusCardBtnBlock,
.plusFeaturesBtnBlock {
  display: flex;
  justify-content: center;
}
.plusCardBtn {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.plusFeaturesBtnBlock {
  margin-top: 20px;
}
.featuresListBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.featturesElementBlock,
.infoCardBlock {
  display: flex;
  gap: 10px;
}
.deleteFeaturesBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.deleteFeaturesBtn {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.deleteFeaturesBtn:hover path {
  stroke: red;
}
